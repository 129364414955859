// src/components/button/Button.tsx
import React, { useState } from 'react';
import './Button.css';

interface ButtonProps {
  buttons: Array<{
    label?: string;
    onClick: () => Promise<void>;
    image?: string;
    type?: 'ok' | 'primary' | 'accent' | 'default';
  }>;
}

const Button: React.FC<ButtonProps> = ({ buttons }) => {
  const [loadingButtons, setLoadingButtons] = useState<number[]>([]);

  const handleClick = async (index: number, onClick: () => Promise<void>) => {
    setLoadingButtons((prev) => [...prev, index]);
    try {
      await onClick();
    } catch (error) {
      console.error('Error in button action:', error);
    } finally {
      setLoadingButtons((prev) => prev.filter((i) => i !== index));
    }
  };

  return (
    <div className="button-group m-3">
      {buttons.map((button, index) => (
        <button
          key={index}
          // className="btn btn-primary m-1"
          className={`button button-${button.type || 'default'} m-1`}
          onClick={() => handleClick(index, button.onClick)}
          disabled={loadingButtons.includes(index)}
        >
          {button.image && !loadingButtons.includes(index) && (
            <img src={button.image} alt={button.label} className="button-image" />
          )}
          {button.label}
          {loadingButtons.includes(index) && (
            <span
              className="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
      ))}
    </div>
  );
};

export default Button;
