// AppRoutes.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GenesisPage from './pages/genesis';
import LoginPage from './pages/login';
import DashboardPage from './pages/dashboard';
import HomePage from './pages/home';
import RhPage from './pages/rh';
import OrderPage from './pages/order';
import StockPage from './pages/stock';
import VendorPage from './pages/vendor';
import ProductionPage from './pages/production';
import ClientPage from './pages/client';
import SupplierPage from './pages/supplier';
import ProductPage from './pages/product';
import MeasureUnitPage from './pages/measure_unit';
import InvoicePage from './pages/invoice';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('access');

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<PrivateRoute component={DashboardPage} />} />
        <Route
          path="/product/corner"
          element={<PrivateRoute component={() => <ProductPage endpoint="corner_product" />} />}
        />
        <Route
          path="/product/disk"
          element={<PrivateRoute component={() => <ProductPage endpoint="disk_product" />} />}
        />
        <Route
          path="/product/reel"
          element={<PrivateRoute component={() => <ProductPage endpoint="reel_product" />} />}
        />
        <Route
          path="/product/reel"
          element={<PrivateRoute component={() => <ProductPage endpoint="reel_product" />} />}
        />
        <Route
          path="/product/sheet"
          element={<PrivateRoute component={() => <ProductPage endpoint="sheet_product" />} />}
        />
        <Route
          path="/product/strip"
          element={<PrivateRoute component={() => <ProductPage endpoint="strip_product" />} />}
        />
        <Route
          path="/product/tray"
          element={<PrivateRoute component={() => <ProductPage endpoint="tray_product" />} />}
        />
        <Route
          path="/rh"
          element={<PrivateRoute component={() => <RhPage endpoint="employee" />} />}
        />
        <Route
          path="/client"
          element={<PrivateRoute component={() => <ClientPage endpoint="client" />} />}
        />
        <Route
          path="/orderitem"
          element={<PrivateRoute component={() => <OrderPage endpoint="orderitem" />} />}
        />
        <Route
          path="/order"
          element={<PrivateRoute component={() => <OrderPage endpoint="order" />} />}
        />
        <Route
          path="/invoice-input"
          element={<PrivateRoute component={() => <InvoicePage endpoint="invoice_input" />} />}
        />
        <Route
          path="/invoice-input-item"
          element={<PrivateRoute component={() => <InvoicePage endpoint="invoice_input_item" />} />}
        />
        <Route
          path="/invoice-output"
          element={<PrivateRoute component={() => <InvoicePage endpoint="invoice_output" />} />}
        />
        <Route
          path="/invoice-output-item"
          element={<PrivateRoute component={() => <InvoicePage endpoint="invoice_output_item" />} />}
        />
        <Route
          path="/production"
          element={<PrivateRoute component={() => <ProductionPage endpoint="shift" />} />}
        />
        <Route
          path="/vendor"
          element={<PrivateRoute component={() => <VendorPage endpoint="vendor" />} />}
        />
        <Route
          path="/stock"
          element={<PrivateRoute component={() => <StockPage endpoint="stock" />} />}
        />
        <Route
          path="/supplier"
          element={<PrivateRoute component={() => <SupplierPage endpoint="supplier" />} />}
        />
        <Route
          path="/frontend/genesis/cliente"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/cliente" />} />}
        />
        <Route
          path="/frontend/genesis/fornecedor"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/fornecedor" />} />}
        />
        <Route
          path="/frontend/genesis/vendedor"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/vendedor" />} />}
        />
        <Route
          path="/frontend/genesis/produtos"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/produtos" />} />}
        />
        <Route
          path="/frontend/genesis/unidades-medida"
          element={
            <PrivateRoute component={() => <GenesisPage endpoint="genesis/unidadesmedida" />} />
          }
        />
        <Route
          path="/frontend/genesis/pedidos"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/pedidos" />} />}
        />
        <Route
          path="/frontend/genesis/itens-pedido"
          element={
            <PrivateRoute component={() => <GenesisPage endpoint="genesis/pedidositens" />} />
          }
        />
        <Route
          path="/frontend/genesis/nf-entrada"
          element={
            <PrivateRoute component={() => <GenesisPage endpoint="genesis/notafiscalentrada" />} />
          }
        />
        <Route
          path="/frontend/genesis/itens-nf-entrada"
          element={
            <PrivateRoute
              component={() => <GenesisPage endpoint="genesis/notafiscalentradaitens" />}
            />
          }
        />
        <Route
          path="/frontend/genesis/nf"
          element={<PrivateRoute component={() => <GenesisPage endpoint="genesis/notafiscal" />} />}
        />
        <Route
          path="/frontend/genesis/itens-nf"
          element={
            <PrivateRoute component={() => <GenesisPage endpoint="genesis/notafiscalitens" />} />
          }
        />
        <Route
          path="/measure-unit"
          element={<PrivateRoute component={() => <MeasureUnitPage endpoint="measure_unit" />} />}
        />
        <Route
          path="/produced/corner"
          element={<PrivateRoute component={() => <ProductionPage endpoint="produced_corner" />} />}
        />
        <Route
          path="/produced/die-cut"
          element={
            <PrivateRoute component={() => <ProductionPage endpoint="produced_die_cut" />} />
          }
        />
        <Route
          path="/produced/pm"
          element={<PrivateRoute component={() => <ProductionPage endpoint="produced_pm" />} />}
        />
        <Route
          path="/produced/rewinder"
          element={
            <PrivateRoute component={() => <ProductionPage endpoint="produced_rewinder" />} />
          }
        />
        <Route path="*" element={<PrivateRoute component={DashboardPage} />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
