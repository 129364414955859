import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import './LoginForm.css';
import { LoginRequest } from '../../types';

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await ApiService.login({ username, password } as LoginRequest);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Usuário ou senha incorretos.');
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="logo-container">
        <img
          className="mb-4"
          src={`${process.env.PUBLIC_URL}/logo512.png`}
          alt="Logo da empresa CPEL"
          width="200"
          height="250"
        />
      </div>

      {/* Exibição da mensagem de erro */}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      <div className="form-floating">
        <input
          type="username"
          className="form-control"
          id="floatingInput"
          name="username"
          placeholder="Usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          autoComplete="username"
        />
        <label htmlFor="floatingInput">Usuário</label>
      </div>
      <div className="form-floating">
        <input
          type="password"
          className="form-control"
          id="floatingPassword"
          name="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete="current-password"
        />
        <label htmlFor="floatingPassword">Senha</label>
      </div>

      {/* <div className="form-check text-start my-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="remember-me"
          id="flexCheckDefault"
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Lembre de mim
        </label>
      </div> */}
      <button className="btn btn-primary w-100 py-2" type="submit">
        Entrar
      </button>
      <div className="footer">
        <p>© 2024</p>
      </div>
    </form>
  );
};

export default LoginForm;
