// src/components/menu/index.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import MenuItem from './item/index';
import DropdownMenu from './DropdownMenu';

const Menu: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/dashboard">
          MasterControl
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <MenuItem to="/client" label="Cliente" />
            <MenuItem to="/stock" label="Estoque" />
            <MenuItem to="/supplier" label="Fornecedor" />
            <DropdownMenu
              label="Genesis"
              items={[
                { to: '/frontend/genesis/cliente', label: 'Cliente' },
                { to: '/frontend/genesis/fornecedor', label: 'Fornecedor' },
                { to: '/frontend/genesis/vendedor', label: 'Vendedor' },
                { to: '/frontend/genesis/produtos', label: 'Produto' },
                { to: '/frontend/genesis/unidades-medida', label: 'Unidades de Medida' },
                { to: '/frontend/genesis/pedidos', label: 'Pedido' },
                { to: '/frontend/genesis/itens-pedido', label: 'Itens do Pedido' },
                { to: '/frontend/genesis/nf-entrada', label: 'Nota Fiscal de Entrada' },
                {
                  to: '/frontend/genesis/itens-nf-entrada',
                  label: 'Itens da Nota Fiscal de Entrada'
                },
                { to: '/frontend/genesis/nf', label: 'Nota Fiscal' },
                { to: '/frontend/genesis/itens-nf', label: 'Itens da Nota Fiscal' }
              ]}
            />
            <DropdownMenu
              label="NF"
              items={[
                { to: '/invoice-output', label: 'Saída' },
                { to: '/invoice-output-item', label: 'Itens Saída' },
                { to: '/invoice-input', label: 'Entrada' },
                { to: '/invoice-input-item', label: 'Itens Entrada' }
              ]}
            />
            <MenuItem to="/order" label="Pedido" />
            <MenuItem to="/orderitem" label="Itens Pedido" />
            <DropdownMenu
              label="Produção"
              items={[
                { to: '/produced/corner', label: 'Cantoneiras' },
                { to: '/produced/die-cut', label: 'Corte e Vinco' },
                { to: '/produced/pm', label: 'Máquina de Papel' },
                { to: '/produced/rewinder', label: 'Rebobinadeira' }
              ]}
            />
            <DropdownMenu
              label="Produto"
              items={[
                { to: '/product/reel', label: 'Bobina' },
                { to: '/product/corner', label: 'Cantoneiras' },
                { to: '/product/sheet', label: 'Chapas' },
                { to: '/product/disk', label: 'Discos' },
                { to: '/product/strip', label: 'Tiras' },
                { to: '/product/tray', label: 'Bandejas' }
              ]}
            />
            <MenuItem to="/rh" label="RH" />
            <MenuItem to="/vendor" label="Vendedor" />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
