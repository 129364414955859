// src/components/paginatedTablePage/index.tsx
import React, { useState, useEffect } from 'react';
import usePaginatedData from '../../hooks/usePaginatedData';
import Menu from '../menu';
import Table from '../table';
import Pagination from '../pagination';
import TableFilter from '../table/filter';
import './PaginatedTablePage.css';

interface PaginatedTablePageProps {
  endpoint: string;
  includeCheckbox?: boolean;
  buttons?: React.ReactNode;
}

const PaginatedTablePage: React.FC<PaginatedTablePageProps> = ({
  endpoint,
  includeCheckbox = true,
  buttons,
}) => {
  const {
    data,
    loading,
    next,
    previous,
    currentPage,
    totalPages,
    handlePageChange,
    getPageUrl,
  } = usePaginatedData<any>({ endpoint });

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    setFilteredData(data); // Inicialmente, os dados filtrados são os dados completos
  }, [data]);

  const handleFilter = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const handleSelectionChange = (selectedData: any[]) => {
    setSelectedItems(selectedData);
  };

  const defaultButtons = (
    <div style={{ minHeight: '48px' }}></div>
  );

  return (
    <div>
      <Menu />
      <div className="container-fluid">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="buttons-container">
            {buttons || defaultButtons}
          </div>
          <div className="pagination-container">
            <Pagination
              next={next}
              previous={previous}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              getPageUrl={getPageUrl}
            />
          </div>
        </div>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {/* <TableFilter data={data} onFilter={handleFilter} /> */}
            <Table
              data={filteredData}
              includeCheckbox={includeCheckbox}
              onSelectionChange={handleSelectionChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PaginatedTablePage;
